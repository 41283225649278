export const environment = {
  production: true,
  appVersion: "v717demo1",
  USERDATA_KEY: "authf649fc9a5f55",
  isMockEnabled: true,

  baseUrl: "https://liveflow.qforms.co",
  apiUrl: "https://liveflowapi.qforms.co/api",
  qformUrl: "https://liveapi.qforms.co",
  qformFrontEndUrl: "https://live.qforms.co",
  INTEGRATION_HUB_FRONTEND_URL: "https://liveihub.qforms.co",
  INTEGRATION_HUB_BACKEND_URL: "https://liveihubapi.qforms.co",

  // baseUrl: "https://flow.qforms.co",
  // apiUrl: "https://flowapi.qforms.co/api",
  // qformUrl: "https://devapi.qforms.co",
  // qformFrontEndUrl: "https://dev.qforms.co",
  // INTEGRATION_HUB_FRONTEND_URL: "https://devih.qforms.co",
  // INTEGRATION_HUB_BACKEND_URL: "https://devihapi.qforms.co",

  servicenow_instanceid: "dev114543",
  servicenow_tablename: "u_mudasir_table_label",
};
export class Config {
  static DATE_FMT = "dd-MM-yyyy";
  static DATE_TIME_FMT = `${Config.DATE_FMT} hh:mma`;
  static secretKey = "drQ8pzjLMqEKZyAhyLqXlxZdyQTsEoiq";
}
